// @flow

/**
 * Module dependencies.
 */

import { type TemplateProps } from 'types/template';
import { getFluidImage } from 'utils/get-fluid-image';
import { graphql, useStaticQuery } from 'gatsby';
import FooterIconsSection from 'components/footer/footer-icons-section';
import Header from 'components/slykigai/header';
import JoinProgram from 'components/slykigai/join-program';
import PageContainer from 'components/page-container';
import React, { type Node } from 'react';
import SlykigaiProgram from 'components/slykigai/slykigai-program';
import SlykigaiTribe from 'components/slykigai/slykigai-tribe';

/**
 * Slykigai page query.
 */

const slykigaiPageQuery = graphql`
  query {
    config {
      app {
        baseUrl
      }
      settings {
        slykigaiFormUrl
      }
    }
    data: allSeegnoCmsSlykigaiPage {
      nodes {
        content {
          header {
            backgroundImage {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
          }
          slykigaiProgram {
            programList {
              title 
              list {
                description
                id
              }
              image {
                file {
                  publicURL
                }
              }
            }
            programReason {
              image {
                file {
                  publicURL
                }
              }
              list {
                description
                id
              }
              title
            }
            programTime {
              description
              hoursPerDay
              image {
                file {
                  publicURL
                }
              }
              numberOfDays
              title
            }
          }
          slykigaiTribe {
            list {
              image {
                file {
                  publicURL
                }
              }
              payspace
            }
          }
          joinProgram {
            image {
              file {
                publicURL
              }
            }
          }
          footerSection {
            leftIcon {
              file {
                publicURL
              }
            }
            leftText
            rightIcon {
              file {
                publicURL
              }
            }
            rightText
          }
        }
      }
    }
  }
`;

/**
 * `Slykigai` template.
 */

const Slykigai = ({ pageContext }: TemplateProps): Node => {
  const { config, data } = useStaticQuery(slykigaiPageQuery);
  const dataContent = data?.nodes[0]?.content;
  const metatags = pageContext?.metatags;
  const content = pageContext?.content;
  const slykigaiFormUrl = config?.settings?.slykigaiFormUrl;

  return (
    <PageContainer
      footerElement={<FooterIconsSection {...dataContent?.footerSection} />}
      metatags={metatags}
    >
      <Header
        backgroundImage={getFluidImage(dataContent?.header, 'backgroundImage')}
        description={content?.header?.description}
        form={content?.header?.form}
        slykigaiFormUrl={slykigaiFormUrl}
        title={content?.header?.title}
      />

      <SlykigaiProgram
        programList={dataContent?.slykigaiProgram?.programList}
        programReason={dataContent?.slykigaiProgram?.programReason}
        programTime={dataContent?.slykigaiProgram?.programTime}
      />

      <SlykigaiTribe
        appUrl={config?.app?.baseUrl}
        description={content?.slykigaiTribe?.description}
        imageList={dataContent?.slykigaiTribe?.list}
        textList={content?.slykigaiTribe?.list}
        title={content?.slykigaiTribe?.title}
      />

      <JoinProgram
        buttonLabel={content?.joinProgram?.buttonLabel}
        description={content?.joinProgram?.description}
        image={dataContent?.joinProgram?.image}
        placeholder={content?.joinProgram?.placeholder}
        slykigaiFormUrl={slykigaiFormUrl}
        title={content?.joinProgram?.title}
      />
    </PageContainer>
  );
};

/**
 * Export `Slykigai` template.
 */

export default Slykigai;
