// @flow

/**
 * Module dependencies.
 */

import { media } from '@seegno-labs/react-components/styles';
import Form from 'components/core/forms/form';
import Input from 'components/core/forms/fields/input';
import Message from 'components/core/message';
import React, { type Node, useCallback, useState } from 'react';
import SubmitButton from 'components/core/forms/submit-button';
import styled from 'styled-components';
import useTranslate from 'hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  buttonLabel: string,
  placeholder: string,
  slykigaiFormUrl: string
|};

/**
 * Json schema.
 */

const jsonSchema = {
  properties: {
    email: {
      format: 'email',
      type: 'string'
    }
  },
  required: ['email'],
  type: 'object'
};

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  ${media.min('ms')`
    max-width: 146px;
  `}
`;

/**
 * `SlykigaiForm` component.
 */

function SlykigaiForm(props: Props): Node {
  const { buttonLabel, placeholder, slykigaiFormUrl } = props;
  const [notification, setNotification] = useState();
  const translate = useTranslate();
  const handleSubmit = useCallback(({ email }, { reset }) => {
    const formData = new URLSearchParams();

    formData.append('EMAIL', email.replace(/ /g, '+'));

    // This request must use `fetch` and with `no-cors` mode
    return fetch(slykigaiFormUrl, {
      body: formData,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'POST',
      mode: 'no-cors'
    })
      .then(() => {
        setNotification({
          message: translate('slykigaiForm.successMessage'),
          type: 'success'
        });

        reset();
      })
      .catch(() => {
        setNotification({
          message: translate('slykigaiForm.errorMessage'),
          type: 'error'
        });
      });
  }, [slykigaiFormUrl, translate]);

  return (
    <>
      <Form
        jsonSchema={jsonSchema}
        onSubmit={handleSubmit}
      >
        <Input
          label={placeholder}
          name={'email'}
        />

        <StyledSubmitButton fullWidth>
          {buttonLabel}
        </StyledSubmitButton>
      </Form>

      {notification && (
        <Message
          onClose={() => setNotification(null)}
          type={notification?.type}
        >
          {notification?.message}
        </Message>
      )}
    </>
  );
}

/**
 * Export `SlykigaiForm` component.
 */

export default SlykigaiForm;
