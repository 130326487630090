// @flow

/**
 * Module dependencies.
 */

import { Icon } from '@seegno-labs/react-components/media';
import type { Image as ImageType } from 'types/image';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { ifProp, prop } from 'styled-tools';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import arrowIcon from 'assets/svg/arrow.svg';
import calendarIcon from 'assets/svg/32/calendar.svg';
import clockIcon from 'assets/svg/32/clock.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  programList: Array<{|
    image: ImageType,
    list: Array<{|
      description: string,
      id: string
    |}>,
    title: string
  |}>,
  programReason: {|
    image: ImageType,
    list: Array<{|
      description: string,
      id: string
    |}>,
    title: string
  |},
  programTime: {|
    description: string,
    hoursPerDay: string,
    image: ImageType,
    numberOfDays: string,
    title: string
  |}
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  padding: ${units(7)} ${units(2)} 0;
  position: relative;

  ${media.min('xs')`
    padding-top: ${units(10)};
  `}

  ${media.min('md')`
    padding: ${units(22.5)} ${units(3)};
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: grid;
  grid-gap: ${units(4)};
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: ${prop('marginBottom', units(7))};

  ${media.min('md')`
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: ${prop('marginBottomMd', units(10))};
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-column: 1 / -1;

  ${media.min('xs')`
    grid-row: 1;

    ${ifProp('invertLayout', css`
      grid-column: 4 / span 3;
    `, css`
      grid-column: 1 / span 3;
    `)}
  `}

  ${media.min('md')`
    align-self: center;

    ${ifProp('invertLayout', css`
      grid-column: 7 / span 5;
    `, css`
      grid-column: 2 / span 5;
    `)}
  `}

  ${media.min('xl')`
    ${ifProp('invertLayout', css`
      grid-column: 7 / span 4;
    `, css`
      grid-column: 3 / span 4;
    `)}
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-column: 1 / -1;

  ${ifProp('isProgramList', css`
    align-items: flex-start;
    display: flex;
    justify-content: center;
  `)}

  ${media.min('xs')`
    grid-row: 1;

    ${ifProp('invertLayout', css`
      grid-column: 1 / span 3;
    `, css`
      grid-column: 4 / span 3;
    `)}
  `}

  ${media.min('md')`
    ${ifProp('invertLayout', css`
      grid-column: 2 / span 4;
    `, css`
      grid-column: 8 / span 4;
    `)}
  `}
`;

/**
 * Title style.
 */

const titleStyle = css`
  color: ${color('secondary')};
  margin-bottom: ${units(1.5)};

  ${media.min('ms')`
    margin-bottom: ${units(3.5)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H3)`
  ${titleStyle}
`;

/**
 * `ProgramListTitle` styled component.
 */

const ProgramListTitle = styled(Type.H2)`
  ${titleStyle}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H6).attrs({ as: 'div' })`
  color: ${color('secondary')};
  margin-bottom: ${units(2)};

  ${media.min('ms')`
    margin-bottom: ${units(4)};
  `}
`;

/**
 * `NotesWrapper` styled component.
 */

const NotesWrapper = styled.div`
  align-items: center;
  color: ${color('blue500')};
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: max-content 1fr;
  margin-bottom: ${units(1)};
`;

/**
 * `Image` styled component.
 */

const Image = styled.img`
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;

  ${media.max('xs')`
    ${ifProp('isProgramList', css`
      max-width: ${units(23.5)};
    `)}
  `}
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  margin-bottom: ${units(1.5)};
  padding-left: ${units(3.75)};
  position: relative;

  &::before {
    background-image: url('data:image/svg+xml;utf8,${encodeURIComponent(arrowIcon)}');
    content: '';
    height: 22px;
    left: 0;
    position: absolute;
    top: 0;
    width: 22px;
  }
`;

/**
 * `SlykigaiProgram` component.
 */

function SlykigaiProgram(props: Props): Node {
  const { programList, programReason, programTime } = props;

  return (
    <Wrapper>
      <Content>
        <TextWrapper>
          <Title>
            <RawHtml>
              {programTime.title}
            </RawHtml>
          </Title>

          <Description>
            <RawHtml>
              {programTime.description}
            </RawHtml>
          </Description>

          <NotesWrapper>
            <Icon
              aria-hidden
              icon={clockIcon}
              size={units(4)}
            />

            <Type.H6 as={'p'}>
              {programTime.hoursPerDay}
            </Type.H6>
          </NotesWrapper>

          <NotesWrapper>
            <Icon
              aria-hidden
              icon={calendarIcon}
              size={units(4)}
            />

            <Type.H6 as={'p'}>
              {programTime.numberOfDays}
            </Type.H6>
          </NotesWrapper>
        </TextWrapper>

        <ImageWrapper>
          <Image src={programTime.image.file.publicURL} />
        </ImageWrapper>
      </Content>

      <Content marginBottomMd={units(25)}>
        <TextWrapper invertLayout>
          <Title>
            <RawHtml>
              {programReason.title}
            </RawHtml>
          </Title>

          <ul>
            {programReason.list.map(item => (
              <ListItem key={item.id}>
                <Type.H6
                  as={'div'}
                  color={color('secondary')}
                >
                  <RawHtml>
                    {item.description}
                  </RawHtml>
                </Type.H6>
              </ListItem>
            ))}
          </ul>
        </TextWrapper>

        <ImageWrapper invertLayout>
          <Image src={programReason.image.file.publicURL} />
        </ImageWrapper>
      </Content>

      {programList.map((listItem, index) => {
        const isOdd = index % 2 === 1;

        return (
          <Content key={index}>
            <ImageWrapper
              invertLayout={isOdd}
              isProgramList
            >
              <Image
                isProgramList
                src={listItem.image.file.publicURL}
              />
            </ImageWrapper>

            <TextWrapper invertLayout={isOdd}>
              <ProgramListTitle>
                <RawHtml>
                  {listItem.title}
                </RawHtml>
              </ProgramListTitle>

              <ul>
                {listItem.list.map(item => (
                  <ListItem key={item.id}>
                    <Type.H6
                      as={'div'}
                      color={color('secondary')}
                    >
                      <RawHtml>
                        {item.description}
                      </RawHtml>
                    </Type.H6>
                  </ListItem>
                ))}
              </ul>
            </TextWrapper>
          </Content>
        );
      })}
    </Wrapper>
  );
}

/**
 * Export `SlykigaiProgram` component.
 */

export default SlykigaiProgram;
