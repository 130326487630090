// @flow

/**
 * Module dependencies.
 */

import { BackgroundImage, Icon } from '@seegno-labs/react-components/media';
import type { Image } from 'types/image';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { slykDomainUrlResolver } from 'utils/url-resolver';
import { theme } from 'styled-tools';
import Button from 'components/core/button';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import slykIcon from 'assets/svg/16/slyk.svg';
import styled from 'styled-components';

/**
 * `List` type.
 */

type List = {|
  buttonLabel: string,
  description: string,
  image: Image,
  name: string,
  payspace: string
|};

/**
 * `Props` type.
 */

type Props = {|
  appUrl: string,
  description: string,
  imageList: {
    ...List,
    career: ?string
  },
  textList: {|
    ...List,
    career?: string
  |},
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('grey200')};
  display: grid;
  grid-column-gap: ${units(4)};
  grid-template-columns: repeat(6, 1fr);
  padding: ${units(8)} ${units(2)};
   
  ${media.min('md')`
    grid-template-columns: repeat(12, 1fr);
    padding: ${units(15)} ${units(3)};
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: center;
  max-width: 681px;
  text-align: center;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  color: ${color('secondary')};
  padding-bottom: ${units(2)};

  > span > p > strong {
    color: ${color('blue500')};
  }
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H6).attrs({ as: 'div' })`
  color: ${color('secondary')};
  padding-bottom: ${units(4)};

  ${media.min('md')`
    padding-bottom: ${units(10)};
  `}
`;

/**
 * `CardsWrapper` styled component.
 */

const CardsWrapper = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-row-gap: ${units(0.5)};
  position: relative;

  ${media.min('ms')`
    grid-auto-rows: 1fr;
    grid-gap: ${units(4)};
    grid-template-columns: repeat(2, minmax(auto, ${units(40.5)}));
    justify-self: center;
  `}

  ${media.min('md')`
    grid-template-columns: repeat(4, minmax(auto, ${units(40.5)}));
    margin: 0 -2px;
  `}
`;

/**
 * `Card` styled component.
 */

const Card = styled.div`
  background-color: ${color('white')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: ${units(2)};

  ${media.min('md')`
    padding: 19px ${units(3)} 29px;
  `}
`;

/**
 * `CardHeader` styled component.
 */

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: max-content 1fr;
  padding-bottom: ${units(1.75)};

  ${media.min('md')`
    grid-column-gap: ${units(2)};
    padding-bottom: ${units(2)};
  `}
`;

/**
 * `CardImage` styled component.
 */

const CardImage = styled.div`
  ${theme('typography.styles.h6')}

  align-items: center;
  background-color: ${color('grey500')};
  clip-path: url(#slyk-mask);
  color: ${color('white')};
  display: flex;
  height: 30px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 30px;

  ${media.min('md')`
    height: 45px;
    width: 45px;
  `}
`;

/**
 * `Name` styled component.
 */

const Name = styled(Type.Label).attrs({ as: 'div' })`
  color: ${color('secondary')};
  font-weight: 700;
  padding-bottom: ${units(0.5)};
`;

/**
 * `Career` styled component.
 */

const Career = styled(Type.Label).attrs({ as: 'p' })`
  color: ${color('grey1000')};
`;

/**
 * `CardDescription` styled component.
 */

const CardDescription = styled(Type.Label).attrs({ as: 'div' })`
  color: ${color('grey1000')};
  flex: 1;
  padding-bottom: ${units(2)};

  ${media.min('md')`
    padding-bottom: ${units(3)};
  `}
`;

/**
 * `CardButton` styled component.
 */

const CardButton = styled(Button)`
  align-items: center;
  border-color: ${color.transparentize('blue500', 0.2)};
  border-radius: 6px;
  color: ${color('blue500')};
  display: grid;
  font-weight: 400;
  grid-column-gap: ${units(1.5)};
  grid-template-columns: repeat(2, max-content);
  min-height: 30px;
  padding: 5px 10px;
  text-transform: initial;
  width: max-content;
`;

/**
 * `SlykigaiTribe` component.
 */

function SlykigaiTribe(props: Props): Node {
  const { appUrl, description, imageList, textList, title } = props;

  return (
    <Wrapper>
      <TextWrapper>
        <Title>
          <RawHtml>
            {title}
          </RawHtml>
        </Title>

        <Description>
          <RawHtml>
            {description}
          </RawHtml>
        </Description>
      </TextWrapper>

      <CardsWrapper>
        {textList.map(item => {
          const imageItem = imageList.find(({ payspace }) => payspace === item.payspace);

          return (
            <Card key={item.payspace}>
              <CardHeader>
                <CardImage>
                  <BackgroundImage defaultUrl={imageItem.image.file.publicURL} />
                </CardImage>

                <div>
                  <Name>
                    <RawHtml>
                      {item.name}
                    </RawHtml>
                  </Name>

                  {item.career && (
                    <Career>
                      {item.career}
                    </Career>
                  )}
                </div>
              </CardHeader>

              <CardDescription>
                <RawHtml>
                  {item.description}
                </RawHtml>
              </CardDescription>

              <CardButton
                colorTheme={'secondary'}
                href={slykDomainUrlResolver(appUrl, item.payspace, true)}
                rel={'external noopener'}
                size={'small'}
                target={'_blank'}
                variant={'outline'}
              >
                <Icon
                  color={color('blue500')}
                  icon={slykIcon}
                  size={units(2)}
                />

                {item.buttonLabel}
              </CardButton>
            </Card>
          );
        })}
      </CardsWrapper>
    </Wrapper>
  );
}

/**
 * Export `SlykigaiTribe` component.
 */

export default SlykigaiTribe;
