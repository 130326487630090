// @flow

/**
 * Module dependencies.
 */

import {
  ErrorMessage,
  FormControl,
  FormField,
  FormGroup,
  HelpMessage,
  Label,
  Status,
  type ValidationStatus
} from '../fields-styled-components';

import { isEmpty, isString } from 'lodash';
import React, { type Node, forwardRef } from 'react';

/**
 * `Value` type.
 */

type Value = ?string | number;

/**
 * Export `InputProps` type.
 */

export type InputProps = {|
  as?: Node,
  className?: string,
  disabled?: boolean,
  error: Object | string,
  helpText?: Node,
  id?: string,
  label: string,
  maxLength?: number,
  name: string,
  normalizeValue?: Value => Value,
  onBlur: Object => void,
  onChange: ?Value => void,
  parseValue?: Value => Value,
  type?: string,
  validationStatus?: ValidationStatus,
  value: Value
|};

/**
 * Default normalize value.
 */

function defaultNormalizeValue(value: Value): Value {
  // $FlowFixMe
  return isString(value) ? value.trim() : value;
}

/**
 * `Input` component.
 */

function Input(props: InputProps, ref): Node {
  const {
    as,
    className,
    disabled,
    error,
    helpText,
    id,
    label,
    name,
    placeholder = ' ',
    validationStatus,
    normalizeValue = defaultNormalizeValue,
    onBlur,
    onChange,
    parseValue,
    value,
    ...rest
  } = props;

  function handleChange(event) {
    const value = event.target.value;
    const parsedValue = parseValue ? parseValue(value) : value;

    onChange(!isEmpty(parsedValue) ? parsedValue : undefined);
  }

  function handleBlur(event) {
    const value = event.target.value;
    const parsedValue = parseValue ? parseValue(value) : value;
    const normalizedValue = normalizeValue(parsedValue);

    onChange(!isEmpty(normalizedValue) ? normalizedValue : undefined);
    onBlur(event);
  }

  return (
    <FormGroup
      className={className}
      disabled={disabled}
      validationStatus={error ? 'invalid' : validationStatus}
    >
      <FormField>
        <FormControl
          {...rest}
          as={as}
          id={id ?? name}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          ref={ref}
          value={value ?? ''}
        />

        <Label
          hasBackground={as === 'textarea'}
          htmlFor={id ?? name}
        >
          {label}
        </Label>

        <Status />
      </FormField>

      <HelpMessage visible={!error}>
        {helpText}
      </HelpMessage>

      <ErrorMessage visible={!!error}>
        {error}
      </ErrorMessage>
    </FormGroup>
  );
}

/**
 * `ForwardedCountryInput` component.
 */
// $FlowFixMe
const ForwardedInput = forwardRef<InputProps<any, any>>(Input);

/**
 * Export `ForwardedInput` component.
 */

export default ForwardedInput;
