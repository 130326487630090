// @flow

/**
 * Module dependencies.
 */

import { useField, useFormState } from '@seegno/react-forms';
import React, { type Node, forwardRef } from 'react';
import SlykInput, { type InputProps } from 'slyk-design-system/forms/input';
import useErrorMessage from 'hooks/use-error-message';

/**
 * `Input` component.
 */

function Input({ disabled, name, ...rest }: InputProps, ref: any): Node {
  const { error, meta, value, ...fieldProps } = useField(name);
  const { isSubmitting } = useFormState();
  const errorMessage = useErrorMessage(error);

  return (
    <SlykInput
      {...rest}
      {...fieldProps}
      disabled={isSubmitting || disabled}
      error={meta.touched ? errorMessage : null}
      id={name}
      name={name}
      ref={ref}
      value={value ?? ''}
    />
  );
}

/**
 * Export `Input` component.
 */

export default forwardRef<InputProps, any>(Input);
