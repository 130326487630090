// @flow

/**
 * Module dependencies.
 */

import type { Image as ImageType } from 'types/image';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import SlykigaiForm from './slykigai-form';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  buttonLabel: string,
  description: string,
  image: ImageType,
  placeholder: string,
  slykigaiFormUrl: string,
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  display: grid;
  grid-column-gap: ${units(4)};
  grid-template-columns: repeat(6, 1fr);
  padding: ${units(4)} ${units(2)} ${units(14)};

  ${media.min('md')`
    grid-template-columns: repeat(12, 1fr);
    padding: ${units(10.5)} ${units(3)} 155px;
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-column: 1 / -1;

  ${media.min('ms')`
    grid-column: 1 / span 3;
    grid-row: 1;
  `}

  ${media.min('md')`
    grid-column: 2 / span 4;
    grid-row: 1;
    max-width: 443px;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  color: ${color('secondary')};
  padding: ${units(4)} 0 ${units(2)};

  > span > p > strong {
    color: ${color('blue500')};
  }

  ${media.min('md')`
    padding-top: ${units(3)};
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Label).attrs({ as: 'div' })`
  color: ${color('grey1000')};
  font-size: 18px;
  letter-spacing: -0.2px;
  line-height: 24px;
  padding-bottom: ${units(3)};
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-column: 1 / -1;

  ${media.min('ms')`
    grid-column: 4 / span 3;
    grid-row: 1;
  `}

  ${media.min('md')`
    grid-column: 7 / -2;
    grid-row: 1;
  `}
`;

/**
 * `Image` styled component.
 */

const Image = styled.img`
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
`;

/**
 * `JoinProgram` component.
 */

function JoinProgram(props: Props): Node {
  const {
    buttonLabel,
    description,
    image,
    placeholder,
    slykigaiFormUrl,
    title
  } = props;

  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={image.file.publicURL} />
      </ImageWrapper>

      <TextWrapper>
        <Title>
          <RawHtml>
            {title}
          </RawHtml>
        </Title>

        <Description>
          <RawHtml>
            {description}
          </RawHtml>
        </Description>

        <SlykigaiForm
          buttonLabel={buttonLabel}
          placeholder={placeholder}
          slykigaiFormUrl={slykigaiFormUrl}
        />
      </TextWrapper>
    </Wrapper>
  );
}

/**
 * Export `JoinProgram` component.
 */

export default JoinProgram;
