// @flow

/**
 * Module dependencies.
 */

import useTranslate from './use-translate';

/**
 * `Error` type.
 */

export type Error = {
  args?: Object,
  rule: string
};

/**
 * `useErrorMessage` hook.
 */

const useErrorMessage = (error: ?Error): ?string => {
  const translate = useTranslate();

  if (!error) {
    return null;
  }

  return translate(`form.errors.${error?.rule}`, error?.args);
};

/**
 * Export `useErrorMessage`.
 */

export default useErrorMessage;
