// @flow

/**
 * Module dependencies.
 */

import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import GatsbyBackgroundImage from 'components/core/images/background-image';
import Lottie from 'react-lottie';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import SlykigaiForm from './slykigai-form';
import slykigaiAnimation from 'assets/animations/slykigai.json';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  backgroundImage?: Object,
  description: string,
  form: {|
    buttonLabel: string,
    description: string,
    placeholder: string
  |},
  slykigaiFormUrl: string,
  title: string
|};

/**
 * `SectionHeader` styled component.
 */

const SectionHeader = styled.header`
  display: grid;
  grid-column-gap: ${units(4)};
  grid-template-columns: repeat(6, 1fr);
  min-height: 100vh;
  padding: ${units(9)} ${units(2)} 0;
  position: relative;

  ${media.min('sm')`
    &::after {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 58.62%, rgba(255, 255, 255, 1) 89.07%);
      bottom: 18%;
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  `}

  ${media.min('md')`
    grid-template-columns: repeat(12, 1fr);
    min-height: 502px;
    padding: ${units(26.25)} ${units(3)} 0;
  `}
`;

/**
 * `BackgroundImage` styled component.
 */

const BackgroundImage = styled(GatsbyBackgroundImage)`
  background-position: bottom center;
  bottom: ${units(27.5)} !important;
  opacity: 1 !important;

  ${media.min('ms')`
    bottom: -155px !important;
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
  justify-self: center;
  max-width: 682px;
  text-align: center;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H1)`
  color: ${color('secondary')};
  margin-bottom: ${units(4)};

  > span > p > strong {
    color: ${color('blue500')};
    font-weight: 600;
  }
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H5).attrs({ as: 'h2' })`
  color: ${color('secondary')};
  margin-bottom: ${units(4.5)};

  ${media.min('ms')`
    margin-bottom: ${units(9)};
  `}
`;

/**
 * `LottieWrapper` styled component.
 */

const LottieWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 2;
  margin-bottom: ${units(5)};
  
  ${media.min('ms')`
    margin-bottom: ${units(9)}; 
  `}

  ${media.min('md')`
    grid-column: 1 / span 7;  
    margin-bottom: 0;
  `}

  ${media.min('lg')`
    grid-column: 2 / span 6;  
  `}
`;

/**
 * `FormWrapper` styled component.
 */

const FormWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 3;

  ${media.min('ms')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.min('md')`
    align-self: center;
    display: initial;
    grid-column: 8 / -1;
    grid-row: 2;
    grid-template-columns: initial;
    max-width: 442px;
  `}

  ${media.min('lg')`
    grid-column: 8 / -2;
  `}
`;

/**
 * `FormDescription` styled component.
 */

const FormDescription = styled(Type.H6).attrs({ as: 'div' })`
  color: ${color('secondary')};
  margin-bottom: ${units(3)};
`;

/**
 * `EmptyRow` styled component.
 */

const EmptyRow = styled.div`
  ${media.min('sm')`
    background-color: ${color('white')};
    bottom: 0;
    height: 20%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  `}
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const {
    backgroundImage,
    description,
    form: {
      buttonLabel,
      description: formDescription,
      placeholder
    },
    slykigaiFormUrl,
    title
  } = props;

  return (
    <SectionHeader>
      <BackgroundImage fluid={backgroundImage} />

      <TextWrapper>
        <Title>
          <RawHtml>
            {title}
          </RawHtml>
        </Title>

        <Description>
          <RawHtml>
            {description}
          </RawHtml>
        </Description>
      </TextWrapper>

      <LottieWrapper>
        <Lottie
          height={'100%'}
          options={{
            animationData: slykigaiAnimation,
            autoplay: true,
            loop: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          width={'100%'}
        />
      </LottieWrapper>

      <FormWrapper>
        <FormDescription>
          <RawHtml>
            {formDescription}
          </RawHtml>
        </FormDescription>

        <SlykigaiForm
          buttonLabel={buttonLabel}
          placeholder={placeholder}
          slykigaiFormUrl={slykigaiFormUrl}
        />
      </FormWrapper>

      <EmptyRow />
    </SectionHeader>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
